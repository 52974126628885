<template>
  <validation-observer ref="simpleRules">
    <div>
      <b-card>
        <div class="d-lg-flex justify-content-between align-items-center">
          <h3 class="font-weight-bolder">
            {{ $t('productType') }}
          </h3>
          <div class="income-order-search order-search">
            <div class="anan-input search-input">
              <div class="anan-input__inner anan-input__inner--normal">
                <input
                  v-model="filter"
                  type="text"
                  :placeholder="$t('search')"
                  class="anan-input__input"
                >
                <div class="anan-input__suffix">
                  <i class="anan-input__clear-btn anan-icon" />
                  <i class="anan-input__suffix-icon anan-icon">
                    <i class="fal fa-search" />
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          class="anan-button--xl-large anan-button anan-button--primary anan-button--normal mt-1"
          @click="showAddNew()"
        >
          <span> {{ $t('addProductType') }} </span>
        </button>
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <b-table
            striped
            hover
            responsive
            :total-rows="totalRows"
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
          >
            <template #cell(number)="data">
              <b-link :to="{ name: 'admin-role-edit', params: { id: data.item.id } }">
                ROLE-{{ data.item.id }}
              </b-link>
            </template>
            <template #cell(index)="data">
              {{ perPage * (currentPage - 1) + (data.index + 1) }}
            </template>

            <template #cell(actions)="data">
              <feather-icon
                icon="Edit3Icon"
                class="mr-1 text-primary cursor-pointer"
                @click="showAddNew(data.item)"
              />

              <feather-icon
                class="text-danger cursor-pointer"
                icon="TrashIcon"
                @click="DeleteData(data.item)"
              />
            </template>
          </b-table>
        </b-overlay>
        <div class="demo-spacing-0 d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getData()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>

      </b-card>

      <b-modal
        id="modal-add-new"
        :title="$t('productTypeInfo')"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        @ok="submitNew()"
      >
        <h4 class="font-weight-bolder">
          {{ $t('productTypeInfo') }}
        </h4>
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Customer Code"
            rules="required"
          >
            <label for="customerCode">{{ $t('firstName') }} : <span class="text-danger">*</span></label>
            <b-form-input
              id="customerCode"
              v-model="productDate.name"
              :state="errors.length > 0 ? false : null"
              type="text"
              :placeholder="$t('firstName')"
            />
            <span class="text-danger">{{ errors[0]? $t('key-295'): '' }}</span>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <!-- <validation-provider
            #default="{ errors }"
            name="Customer Code"
            rules="required"
          > -->
            <label for="customername_en">{{ $t('firstName') }} English : </label>
            <b-form-input
              id="customername_en"
              v-model="productDate.name_en"
              type="text"
              :placeholder="$t('firstName')"
            />
            <!-- :state="errors.length > 0 ? false : null" -->
            <!-- <span class="text-danger">{{ errors[0]? $t('key-295'): '' }}</span>
          </validation-provider> -->
        </b-form-group>
        <b-form-group>
          <!-- <validation-provider
            #default="{ errors }"
            name="Customer Code"
            rules="required"
          > -->
            <label for="customername_cn">{{ $t('firstName') }} 中国 : </label>
            <b-form-input
              id="customername_cn"
              v-model="productDate.name_cn"
              
              type="text"
              :placeholder="$t('firstName')"
            />
            <!-- <span class="text-danger">*</span>
            :state="errors.length > 0 ? false : null" -->
            <!-- <span class="text-danger">{{ errors[0]? $t('key-295'): '' }}</span>
          </validation-provider> -->
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Customer name"
            rules="required"
          >
            <label for="customerCode">ชื่อย่อ : <span class="text-danger">*</span></label>
            <b-form-input
              id="customerCodename"
              v-model="productDate.initial"
              :state="errors.length > 0 ? false : null"
              type="text"
              :placeholder="$t('firstName')"
            />
            <span class="text-danger">{{ errors[0] ? $t('key-295'): '' }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="amount">{{ $t('description') }}. : <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            name="Detail"
            rules="required"
          >
            <b-form-textarea
              id="detail"
              v-model="productDate.detail"
              :placeholder="$t('details')"
              :state="errors.length > 0 ? false : null"
            />
            <span class="text-danger">{{ errors[0]? $t('key-295'): '' }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Type"
            rules="required"
          >
            <label for="type">{{ $t('type') }} : <span class="text-danger">*</span></label>
            <b-form-select
              v-model="productDate.type"
              :options="productlist"
              :state="errors.length > 0 ? false : null"
            />
            <span class="text-danger">{{ errors[0] ? $t('key-295'): ''}}</span>
          </validation-provider>
        </b-form-group>

        <h4 class="font-weight-bolder mt-3">
          {{ $t('pricing') }}
        </h4>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="KG"
            rules="required"
          >
            <label for="amount">{{ $t('kilo') }}. : <span class="text-danger">*</span></label>
            <b-input-group class="input-group-merge">
              <b-form-input
                id="KG"
                v-model="productDate.kilo"
                placeholder=""
                :state="errors.length > 0 ? false : null"
              />
              <b-input-group-append is-text>
                {{ $t('baht') }}
              </b-input-group-append>
            </b-input-group>
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="CBM"
            rules="required"
          >
            <label for="amount">{{ $t('cubic') }} : <span class="text-danger">*</span></label>
            <b-input-group class="input-group-merge">
              <b-form-input
                id="CBM"
                v-model="productDate.queue"
                placeholder=""
                :state="errors.length > 0 ? false : null"
              />
              <b-input-group-append is-text>
                {{ $t('baht') }}
              </b-input-group-append>
            </b-input-group>
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-modal>
    </div>
  </validation-observer>
</template>

<script>
import {
  BTable, BCard, BLink, BModal, BPagination, BFormInput, BFormSelect, BFormTextarea, BFormGroup, BInputGroup, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BLink,
    BModal,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
  },
  data() {
    return {
      required,
      search: '',
      isActive: 'all',
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      filter: null,
      items: [],

      productDate: {
        id: null,
        name: null,
        detail: null,
        type: null,
        kilo: 0,
        queue: 0,
        agent_id: null,
        initial: null,
        name_cn: null,
        name_en: null

      },
      showOver: false,
    }
  },
  computed: {
    productlist() {
      return [
        { text: this.$t('truck'), value: 1 },
        { text: this.$t('ship'), value: 2 },
      ]
    },
    fields() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'name', label: this.$t('firstName'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'detail', label: this.$t('details'), sortable: false, thStyle: { width: '35%' }, thClass: 'text-center',
        },
        {
          key: 'type',
          label: this.$t('type'),
          sortable: true,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
          // eslint-disable-next-line no-nested-ternary
          formatter: value => (value === 1 ? this.$t('truck') : value === 2 ? this.$t('ship') : '-'),
        },
        {
          key: 'kilo', label: this.$t('kilo'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-right',
        },
        {
          key: 'queue', label: this.$t('cubic'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-right',
        },
        {
          key: 'actions', label: this.$t('actions'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData('all')
  },
  methods: {
    submitAddNew() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const params = {
            customerCode: this.customerCode,
            type: this.type,
            detail: this.detail,
            amount: this.amount,
            prefix: this.prefix,
            prefixNum: this.prefixNum,
          }
          this.$http.post('groupbyuser/list/create', params).then(() => {
            this.getData(this.isActive)
            this.$bvToast.toast(this.$t('key-279'), {
              title: this.$t('key-280'),
              variant: 'success',
              solid: true,
              autoHideDelay: 1500,
            })
          })
          this.$bvModal.hide('modal-add-new')
        }
      })
    },
    showAddNew(item) {
      this.$bvModal.show('modal-add-new')
      if (item) {
        this.productDate.id = item.id
        this.productDate.name = item.name
        this.productDate.detail = item.detail
        this.productDate.type = item.type
        this.productDate.kilo = item.kilo
        this.productDate.queue = item.queue
        this.productDate.initial = item.initial
        this.productDate.name_en = item.name_en
        this.productDate.name_cn = item.name_cn
      } else {
        this.productDate.id = null
        this.productDate.name = null
        this.productDate.detail = null
        this.productDate.type = null
        this.productDate.kilo = 0
        this.productDate.queue = 0
        this.productDate.initial = null
        this.productDate.name_en = null
        this.productDate.name_cn = null
      }
    },
    async getData(type) {
      this.isActive = type
      this.showOver = true
      try {
        const params = {
          perPage: this.perPage,
          page: this.currentPage,
        }
        const { data: res } = await this.$http.get('/product_type/showall', { params })
        // console.debug(res)
        if (res.status) {
          this.items = res.data.data
          this.totalRows = res.data.total
        }
        this.showOver = false
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    EditRole(data) {
      this.$router.push({ name: 'admin-role-edit', params: { id: data.id } })
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm(this.$t('key-281'), {
        title: this.$t('key-217'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              id: data.id,
            }
            this.$http.post('/product_type/product_delete', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast(this.$t('key-282'), {
                title: this.$t('key-280'),
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    submitNew() {
      const obj = {
        id: this.productDate.id,
        name: this.productDate.name,
        detail: this.productDate.detail,
        type: this.productDate.type,
        kilo: this.productDate.kilo,
        queue: this.productDate.queue,
        initial: this.productDate.initial,
        name_en: this.productDate.name_en,
        name_cn: this.productDate.name_cn
        // rfgg: '',
      }
      this.$http
        .post('/product_type/product_update', obj)
        .then(() => {
          this.getData(this.isActive)
          this.$bvToast.toast(this.$t('key-260'), {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          console.log(error)
          // this.SwalError(error.response.data.message)
        })
    },

  },
}
</script>

<style lang="scss" scoped></style>
